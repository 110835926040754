<template>
  <app-card v-if="!isLoading && tableData.length">
    <TableTitle v-if="title" :title="title" />

    <AppFilter
      v-if="!isLoading"
      v-model="filter"
      type="game"
    />

    <AppTable
      v-if="!isLoading && items.length"
      :data="currentPageItems"
      :fields="{
        place: 'Место',
        name: 'Название',
        genre: 'Жанр',
        release: 'Дата выхода',
        points: 'Оценка',
        scale: 'Шкала об-cти'
      }"
      @selected="openElement"
    />

    <AppPagination
      v-if="!isLoading && items.length"
      v-model="page"
      :data="items"
      :items-count="PAGE_ITEMS_COUNT"
    />

    <AppEmpty
      v-if="!isLoading && !items.length"
      title="Ничего нет..."
    />
  </app-card>
</template>

<script>
import { useDataLoader } from '@/use/data-loader'
import { useFilterAndPagination } from '@/use/filter-and-pagination'
import AppTable from '../components/ui/AppTable'
import AppPagination from '../components/ui/AppPagination'
import AppFilter from '../components/ui/AppFilter'
import AppEmpty from '../components/ui/AppEmpty'
import TableTitle from '../components/ui/TableTitle'
import AppCard from '@/components/ui/AppCard'

export default {
  components: {
    AppTable,
    AppPagination,
    AppFilter,
    AppEmpty,
    TableTitle,
    AppCard
  },
  setup() {
    return {
      ...useDataLoader('games'),
      ...useFilterAndPagination('games')
    }
  }
}
</script>
